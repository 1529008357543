import React from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

function About() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();


  if (!isLoggedIn) {
    console.log("nem bent");
    navigate('/login');
  } else {

  return (
    <div className="container my-5">
      <h2>Rólunk</h2>
    </div>
  );
}
}

export default About;