import React, { createContext, useContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(() => {
    // Ellenőrizzük, hogy van-e mentett állapot a localStorage-ban
    const isAuth = localStorage.getItem('isLoggedIn');
    return isAuth === 'true';  // A localStorage csak stringeket tárol, így konvertálni kell
  });

  useEffect(() => {
    // Frissítjük a localStorage állapotát, amikor az isLoggedIn változik
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);