import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Register() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const onSubmit = async data => {
    try {
      const response = await fetch('https://proba.jrwebdesign.hu/teszt/backend/register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
      });

      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        navigate('/login');
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      alert('Registration failed: ' + error.message);
    }
    navigate('/login');
  };

  return (
    <div className="container my-5">
      <h2>Regisztráció</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">Felhasználónév</label>
          <input 
            id="username" 
            className="form-control" 
            {...register('username', { required: "Kötelező" })} 
          />
          {errors.username && <span className="text-danger">{errors.username.message}</span>}
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input 
            id="email" 
            type="email" 
            className="form-control" 
            {...register('email', { 
              required: "Kötelező", 
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Nem email formátum."
              }
            })} 
          />
          {errors.email && <span className="text-danger">{errors.email.message}</span>}
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Jelszó</label>
          <input 
            id="password" 
            type="password" 
            className="form-control" 
            {...register('password', { 
              required: "Kötelező",
              minLength: {
                value: 8,
                message: "Minimum 8 karakter"
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                message: "Egy nagybetű, egy kisbetű és egy szám kötelező"
              }
            })} 
          />
          {errors.password && <span className="text-danger">{errors.password.message}</span>}
        </div>
        <button type="submit" className="btn btn-primary">Regisztráció</button>
      </form>
    </div>
  );
}

export default Register;
