import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p>© 2024 Cégünk neve. Minden jog fenntartva.</p>
        <p>
          Kövess minket: 
          <a href="http://">Link</a>, 
        </p>
      </div>
    </footer>
  );
}

export default Footer;