import React from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

function LogoutButton() {
  const { isLoggedIn, setLoggedIn } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem('isLoggedIn');  // Töröljük a bejelentkezés állapotát
    navigate('/');
  };

  // Csak akkor jelenítsük meg a gombot, ha a felhasználó be van jelentkezve
  return isLoggedIn ? (
    <button className="btn btn-primary" onClick={handleLogout}>Kilépés</button>
  ) : null;  // Ha nem jelentkezett be, ne jelenítsünk meg semmit
}

export default LogoutButton;
