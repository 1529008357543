import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Login() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { setLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  const onSubmit = async (data) => {
    try {
      const response = await fetch('https://proba.jrwebdesign.hu/teszt/backend/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
      });
      if (!response.ok) {
        console.error('HTTP error:', response.status, response.statusText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();
      if (responseData.message === "Belépés sikeres!") {
        setLoggedIn(true);
        navigate(from.pathname, { replace: true });
      } else {
        throw new Error(responseData.message);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      alert(error.message);
    }
  };

  return (
    <div className="container my-5">
      <h2>Belépés</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">Felhasználónév</label>
          <input 
            id="username" 
            className="form-control" 
            {...register('username', { required: "Felhasználónév megadása kötelező" })} 
          />
          {errors.username && <span className="text-danger">{errors.username.message}</span>}
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Jelszó</label>
          <input 
            id="password" 
            type="password" 
            className="form-control" 
            {...register('password', { 
              required: "Jelszó megadása kötelező",
              minLength: {
                value: 8,
                message: "A jelszónak legalább 8 karakter hosszúnak kell lennie"
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                message: "A jelszónak tartalmaznia kell legalább egy nagybetűt, egy kisbetűt és egy számot"
              }
            })} 
          />
          {errors.password && <span className="text-danger">{errors.password.message}</span>}
        </div>
        <button type="submit" className="btn btn-primary">Belépés</button>
      </form>
    </div>
  );
}

export default Login;
