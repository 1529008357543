import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/index.css"; // Ha van stílusod, akkor ez is legyen itt


// Létrehozzuk a gyökér elemet
const root = ReactDOM.createRoot(document.getElementById('root'));

// A gyökér elemhez rendeljük az App komponenst
root.render(<App />);