import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Navbar, Nav, Container } from 'react-bootstrap';
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Registration from "./components/Registration";
import Login from "./components/Login";
import LogoutButton from './components/LogoutButton';
import { AuthProvider, useAuth } from './AuthContext';
import Footer from './components/Footer';


function App() {
  return (
    <AuthProvider>
      <Router>
        <MainContent />
        <Footer />
      </Router>
    </AuthProvider>
  );
}

function MainContent() {
  const { isLoggedIn } = useAuth();
  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">(HTML,React)+PHP</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Kezdőlap</Nav.Link>
              {isLoggedIn && <Nav.Link as={Link} to="/about">Rólunk</Nav.Link>}
              <Nav.Link as={Link} to="/services">Szolgáltatások</Nav.Link>
              <Nav.Link as={Link} to="/contact">Kapcsolat</Nav.Link>
              <Nav.Link as={Link} to="/registration">Regisztráció</Nav.Link>
              {!isLoggedIn && <Nav.Link as={Link} to="/login">Belépés</Nav.Link>}
            </Nav>
          </Navbar.Collapse>
          {isLoggedIn && <LogoutButton />}
        </Container>
      </Navbar>

        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
        </Routes>
    </div>
  );
}

export default App;
